<template>
    <span>
        <img :src="appLogoImage" width="60" class="mb-75">
        <img  :src="appName">

    </span>
</template>

<script>
import { $themeConfig } from '@themeConfig'
export default {
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appLogoImage,
      appName
    }
  },
}
</script>
